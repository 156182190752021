<template>
    <section class="content">
        <div>
        	Hubungi Admin
        </div>
    </section>
</template>
<script>
    export default {
    name: 'Contact',
    data() {
        return {
            
        };
    }
}
</script>
<style type="text/css" scoped>
</style>